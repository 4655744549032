import { Box, Typography } from '@mui/material';

const Footer = () => {
    return (
        <Box className="footer-wrapper">
            <Typography variant="body2">E-mail : <strong className="sub-title-box">credaigandhinagar@gmail.com</strong> | Copyright © 2008-2015. Credai Gandhinagar (Gandhinagar
                Builders
                Association-GBA).
            </Typography>
            <Typography variant='body2'>All rights reserved. Site best view in Google Chrome.</Typography>
        </Box>
    );
}

export default Footer;