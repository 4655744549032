import { Typography, Box } from "@mui/material";

const BecomeMember = () => {
    return (
        <Box position="relative">
            <Typography variant="h4" className="material-title">Become a Member</Typography>
            <Box className="material-card">
                <Typography variant="body1">When you join the Credai Gandhinagar (Gandhinagar Builders Association-GBA),
                    you'll have access to an extensive list of benefits that will help you improve your business and
                    professionalism. Since 1992 the Credai Gandhinagar (Gandhinagar Builders Association-GBA) has been
                    representing and serving the collective interests of residential builders and associated professionals
                    in the Gandhinagar. The Credai Gandhinagar today represents more than 125 companies, and serves as the
                    voice of the
                    industry.</Typography>
                <Typography variant="body1">Membership in the Credai Gandhinagar provides direct exposure to more than 100 industry
                    professionals,
                    as well as to the home-buying public, through company listings in the annual Member Directory and on
                    www.credaigandhinagar.com, in the Find-A-Member section.</Typography>
                <Typography variant="body1">Membership in your builders association allows you to give back to the industry that has
                    given you so much – through educational and community service programs, charitable aid, and more. You,
                    your ideas and your help are needed to build an even stronger Association, which in turns gives you
                    stronger representation at the city, county, state and national levels. Lend your stature to help
                    housing grow and prosper as an industry and as a profession.</Typography>

                <Typography variant="h6" className="title-box" marginY={2}>Need more incentive? The list goes on and on…</Typography>

                <Typography variant="subtitle1" className="sub-title-box" marginY={1}>Make Strategic Contacts</Typography>

                <Typography variant="body1">As a member-led organization, opportunities abound within the Credai Gandhinagar for you
                    to get involved
                    AND to create strategic business partnerships that benefit you and your company – they can be fun too!
                    There are so many ways to get involved – both formal and informal. For example, participate in the
                    annual technical tours foreign tour & also CREDAI seminars in various cities in India & abroad with
                    fellow industry professionals. Attend monthly membership general meetings – they are informative and
                    entertaining!</Typography>
                <Typography variant="subtitle1" className="sub-title-box" marginY={1}>Sharpen Your Knowledge</Typography>
                <Typography variant="body1">Stay current on new building ideas and technology, learn strategies for running your
                    business and get the cutting edge on sales and marketing techniques. These are just a few examples of
                    the learning opportunities available through Credai Gandhinagar. Our continuing education programs are
                    created to help
                    your business thrive and to improve the overall professionalism of the home building industry.</Typography>

                <Typography variant="body1"><strong>Connect with Consumers</strong> through Credai Gandhinagar Member Logo - Consumer
                    recognition is
                    important to you. We strive for the home-buying public to turn to Credai Gandhinagar as the reliable
                    course when making
                    decisions about their future in home buying. The recognizable Credai Gandhinagar logo is provided to
                    members upon
                    request for use on promotional items, advertisements, etc.</Typography>

                <Typography variant="subtitle1" className="sub-title-box" marginY={1}>Professional Recognition</Typography>
                <Typography variant="body1">Enhance your professional credibility and visibility by belonging to one of the nation's
                    most highly-respected and widely-known trade associations. Not sure how to do that? Get involved. Join a
                    committee. Attend membership meetings. Teach a className. Work on a community service project. Do just one
                    of these, and you will see - professional recognition comes easy when you get involved!</Typography>

                <Typography variant="subtitle1" className="sub-title-box" marginY={1}>Protect and Promote the Building Industry</Typography>
                <Typography variant="body1">The Association acts as a source of timely and reliable information concerning the local
                    building industry, and works to eliminate overly-restrictive and costly building laws and regulations
                    that drive up the cost of housing. Credai Gandhinagar provides a forum through which businesses can work
                    together to
                    remain informed regarding new and proposed governmental polices which affect the housing industry.</Typography>

                <Typography variant="subtitle1" className="sub-title-box" marginY={1}>Dispute Resolution</Typography>
                <Typography variant="body1">Because the building/remodeling process is complex, often extending over a substantial
                    length of time and involving many different individuals; and, as even the best written contracts,
                    specifications and plans leave room for legitimate interpretational differences, Credai Gandhinagar
                    offers its
                    resources and technical knowledge to assist in resolving construction-related disputes. Credai
                    Gandhinagar's
                    Construction Arbitration Board will mediate in both builder/consumer and builder/subcontractor disputes.
                    A full description of the requirements of the process is available in the Resource section of
                    www.credaigandhinagar.com</Typography>
            </Box>
        </Box>
    );
}

export default BecomeMember;