import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import Image1 from "../Assets/Images/Infrastructure/1.jpg";
import Image2 from "../Assets/Images/Infrastructure/2.jpg";
import Image3 from "../Assets/Images/Infrastructure/3.jpg";
import Image4 from "../Assets/Images/Infrastructure/4.jpg";
import Image5 from "../Assets/Images/Infrastructure/5.jpg";
import Image6 from "../Assets/Images/Infrastructure/6.jpg";
import Image7 from "../Assets/Images/Infrastructure/7.jpg";
import Image8 from "../Assets/Images/Infrastructure/8.jpg";
import Image9 from "../Assets/Images/Infrastructure/9.jpg";
import Image10 from "../Assets/Images/Infrastructure/10.jpg";
import Image11 from "../Assets/Images/Infrastructure/11.jpg";
import Image12 from "../Assets/Images/Infrastructure/12.jpg";
import Image13 from "../Assets/Images/Infrastructure/13.jpg";
import Image14 from "../Assets/Images/Infrastructure/14.jpg";
import Image15 from "../Assets/Images/Infrastructure/15.jpg";
import Image16 from "../Assets/Images/Infrastructure/16.jpg";
import Image17 from "../Assets/Images/Infrastructure/17.jpg";
import Image18 from "../Assets/Images/Infrastructure/18.jpg";
import Image19 from "../Assets/Images/Infrastructure/19.jpg";
import Image20 from "../Assets/Images/Infrastructure/20.jpg";
import Image21 from "../Assets/Images/Infrastructure/21.jpg";
import Image22 from "../Assets/Images/Infrastructure/22.jpg";
import Image23 from "../Assets/Images/Infrastructure/23.jpg";
import Image24 from "../Assets/Images/Infrastructure/24.jpg";
import Image25 from "../Assets/Images/Infrastructure/25.jpg";
import Image26 from "../Assets/Images/Infrastructure/26.jpg";
import ImageDialog from '../Components/ImageDialog';

interface ImageDialogType {
    open: boolean,
    image: any
}

const Gallery = () => {

    const defaultImageDialog = {
        open: false,
        image: null
    }
    const [imageDialog, setImageDialog] = useState<ImageDialogType>(defaultImageDialog)

    return (
        <Box padding="10px">
            <Grid className='imageContainer' container display="flex" flexDirection="row" flexWrap="wrap" justifyContent={"center"} gap={2}>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image1 })} width={"auto"} height="150px" src={Image1} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image2 })} width={"auto"} height="150px" src={Image2} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image3 })} width={"auto"} height="150px" src={Image3} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image4 })} width={"auto"} height="150px" src={Image4} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image5 })} width={"auto"} height="150px" src={Image5} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image6 })} width={"auto"} height="150px" src={Image6} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image7 })} width={"auto"} height="150px" src={Image7} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image8 })} width={"auto"} height="150px" src={Image8} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image9 })} width={"auto"} height="150px" src={Image9} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image10 })} width={"auto"} height="150px" src={Image10} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image11 })} width={"auto"} height="150px" src={Image11} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image12 })} width={"auto"} height="150px" src={Image12} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image13 })} width={"auto"} height="150px" src={Image13} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image14 })} width={"auto"} height="150px" src={Image14} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image15 })} width={"auto"} height="150px" src={Image15} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image16 })} width={"auto"} height="150px" src={Image16} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image17 })} width={"auto"} height="150px" src={Image17} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image18 })} width={"auto"} height="150px" src={Image18} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image19 })} width={"auto"} height="150px" src={Image19} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image20 })} width={"auto"} height="150px" src={Image20} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image21 })} width={"auto"} height="150px" src={Image21} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image22 })} width={"auto"} height="150px" src={Image22} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image23 })} width={"auto"} height="150px" src={Image23} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image24 })} width={"auto"} height="150px" src={Image24} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image25 })} width={"auto"} height="150px" src={Image25} alt="" /></Grid>
                <Grid item><img onClick={() => setImageDialog({ open: true, image: Image26 })} width={"auto"} height="150px" src={Image26} alt="" /></Grid>
            </Grid>
            {
                imageDialog.open && <ImageDialog open={imageDialog.open} image={imageDialog.image} handleClose={() => setImageDialog(defaultImageDialog)} />
            }
        </Box>
    );
}

export default Gallery;