import radhe_time from './../Assets/Images/Projects/radhe_time.jpg';
import shre_rang from './../Assets/Images/Projects/shre_rang.jpg';
import shubh_arambh from './../Assets/Images/Projects/shubh_arambh.jpg';
import pramukh_oasis from './../Assets/Images/Projects/pramukh_oasis.jpg';
import sarthak_camphore_5 from './../Assets/Images/Projects/sarthak_camphore_5.jpg';
import sarthak_homes_6 from './../Assets/Images/Projects/sarthak_homes_6.jpg';
import shreenath_sarthak_industrial_park_7 from './../Assets/Images/Projects/shreenath_sarthak_industrial_park_7.jpg';
import strawberry_bunglows_1 from './../Assets/Images/Projects/strawberry_bunglows_1.jpg';
import capital_flora from './../Assets/Images/Projects/capital_flora.jpg';
import mahalaxmi_royal_place_3 from './../Assets/Images/Projects/mahalaxmi_royal_place_3.jpg';
import coconut_business_park_4 from './../Assets/Images/Projects/coconut_business_park_4.jpg';
import kanam from './../Assets/Images/Projects/kanam.jpg';
import pramukh_corporate from './../Assets/Images/Projects/pramukh_corporate.jpg';
import hariGold from './../Assets/Images/Projects/hari-gold.png';
import gokulDham from './../Assets/Images/Projects/gokul_dham.jpg';
import bansari from './../Assets/Images/Projects/bansari.jpg';
import shri_rang_pineaple from './../Assets/Images/Projects/shri_rang_pineaple.jpg';
import silicon_velly from './../Assets/Images/Projects/silicon_velly_(revised)_a211.jpg';
import radhe_nilesh_patel_1 from './../Assets/Images/Projects/radhe_nilesh_patel_1.jpg';
import shalin_atelier from './../Assets/Images/Projects/shalin_atelier.jpg';
import { CommitteeMemberType, MemberType, PastPresidentType } from './Interfaces';

export const HeaderMenuList = [
    {
        label: "Home",
        link: '/home/'
    },
    {
        label: "Projects",
        link: '/home/project-details'
    },
    {
        label: "Members",
        link: '/home/members'
    },
    // {
    //     label: "Events",
    //     link: '/home/events'
    // },
    {
        label: "Gallery",
        link: '/home/gallery'
    },
    {
        label: "Contact Us",
        link: '/home/contact-us'
    },
]

export const SideMenuList = [
    {
        label: "Why GBA?",
        link: '/home/why-gba',
    },
    {
        label: "Become a Member",
        link: '/home/become-member',
    },
    {
        label: "Grow You Business",
        link: '/home/grow-your-business',
    },
    {
        label: "Member Benefits",
        link: '/home/member-benefit',
    },
    {
        label: "Message from Presidents",
        link: '/home/presidents-message',
    },
    {
        label: "Our Commitment",
        link: '/home/we-committed',
    },
]
export const Projects = [
    {
        "name": "Radhe Times Square",
        "type": "Showrooms, Shops, Banquet Hall, Corporate Offices, Restaurant",
        "address": "Nr. Hotel Management Institute @ Kudasan, Gandhinagar",
        "contactNo": "90999-02909",
        "companyLogo": radhe_time
    },
    {
        "name": "Shree Rang Sky Way",
        "type": "Shops, Offices, Showrooms, Banquets & Restaurants",
        "address": "S.P. Ring Road, nr. Gamthi Hotel @ Nana Chiloda",
        "contactNo": "98252-85800",
        "companyLogo": shre_rang
    },
    {
        "name": "Subh Aarambh",
        "type": "1 & 2 bhk Flats & Shops",
        "address": "Nr. Dholakuva Circle @ Randesan, Gandhinagar",
        "contactNo": "98253-08625",
        "companyLogo": shubh_arambh
    },
    {
        "name": "Pramukh Oasis",
        "type": "2 & 3 bhk Apartments & Shops",
        "address": "@ Sargasan Chokdi, S.G. Highway, Gandhinagar",
        "contactNo": "98253-08625",
        "companyLogo": pramukh_oasis
    },
    {
        "name": "Sarthak Camphore",
        "type": "4bhk Luxurious Bunglows",
        "address": "Plot No: 44, TP-19, PDPU Road, Next Turn To D.M Service Station, Raysan Gandhinagar 382421",
        "contactNo": "N/A",
        "companyLogo": sarthak_camphore_5
    },
    {
        "name": "Sarthak Homes",
        "type": "4bhk Luxurious Bunglows",
        "address": "PDPU Road, Bhaijipura Cross Road, raysan, koba-gandhinagar Highway, Gandhinagar - 382421",
        "contactNo": "N/A",
        "companyLogo": sarthak_homes_6
    },
    {
        "name": "Shreenath Sarthak Industrial Park",
        "type": "4bhk Luxurious Bunglows",
        "address": "PDPU Road, Bhaijipura Cross Road, raysan, koba-gandhinagar Highway, Gandhinagar - 382421",
        "contactNo": "N/A",
        "companyLogo": shreenath_sarthak_industrial_park_7
    },
    {
        "name": "Aawaass Strawberry 4BHK Bunglows",
        "type": "1bhk , 2bhk & 3bhk Luxurious Flats",
        "address": "Opp.Lane of City Pulse Cinema, Koba-Gandhinagar Highway, Kudasan-Por Road, Kudasan, Gandhinagar- 382421",
        "contactNo": "N/A",
        "companyLogo": strawberry_bunglows_1
    },
    {
        "name": "CAPITAL FLORA",
        "type": "Luxurious Bunglows",
        "address": "Opp.Lane of City Pulse Cinema, Koba-Gandhinagar Highway, Kudasan-Por Road, Kudasan, Gandhinagar - 382421",
        "contactNo": "N/A",
        "companyLogo": capital_flora
    },
    {
        "name": "MAHALAXMI ROYAL PLACE",
        "type": "3 & 4 bhk ",
        "address": "Por-Kudasan Road Kudasan, Gandhinagar",
        "contactNo": "N/A",
        "companyLogo": mahalaxmi_royal_place_3
    },
    {
        "name": "Aawaass Coconut Business Park",
        "type": "Shops",
        "address": "Opp.Lane of City Pulse Cinema, Koba-Gandhinagar Highway, Kudasan-Por Road, Kudasan, Gandhinagar - 382421.",
        "contactNo": "N/A",
        "companyLogo": coconut_business_park_4
    },
    {
        "name": "Kanam Residency - 2",
        "type": "2 & 3 bhk Flats & Shops",
        "address": "Shivalay road, Kudasan, Gandhinagar",
        "contactNo": "97238-10881",
        "companyLogo": kanam
    },
    {
        "name": "Pramukh Corporate",
        "type": "Showrooms, Shops & Offices",
        "address": "PDPU Road, Raisan, Gandhianagar",
        "contactNo": "98255-06929",
        "companyLogo": pramukh_corporate
    },
    {
        "name": "Hari Gold",
        "type": "2 bhk Apartments & Shops",
        "address": "Shivalay Road Kudasan, Gandhianagar",
        "contactNo": "98985-44877",
        "companyLogo": hariGold
    },
    {
        "name": "Gokul Dham",
        "type": "1 & 2 bhk Life Style Apartments",
        "address": "Mota Chiloda, Dist Gandhianagr",
        "contactNo": "97255-02416",
        "companyLogo": gokulDham
    },
    {
        "name": "Bansari Green Resorts",
        "type": "Week-End Homes on River Bank Sabarmati",
        "address": "Nr. Grambharti Aluva Dist - Gandhianagar",
        "contactNo": "98790-77177",
        "companyLogo": bansari
    },
    {
        "name": "Shri Rang Pineapple",
        "type": "Shri Rang Pineapple",
        "address": "Tapovan Circle, Visat-Koba Highway, Chandkheda, A'bad",
        "contactNo": "www.shreeranggroup.com",
        "companyLogo": shri_rang_pineaple
    },
    {
        "name": "Silicon Vally",
        "type": "Silicon infra",
        "address": "Behind Radhe Arcad, Near City Pulse Cenema, Kudasan, Gandhinagar",
        "contactNo": "N/A",
        "companyLogo": silicon_velly
    },
    {
        "name": "Radhe Crystal",
        "type": "Parth Corporation",
        "address": "Near Sahjanand park, Kudasan, Gandhinagar",
        "contactNo": "N/A",
        "companyLogo": radhe_nilesh_patel_1
    },
    {
        "name": "Shalin atelier business park",
        "type": "Avs infra con Pvt. ltd",
        "address": "Prahladnagar off. S.G. road, Ahmedabad",
        "contactNo": "www.avsdevelopers.com",
        "companyLogo": shalin_atelier
    }
]





export const MemberList: Array<MemberType> = [
    {
        name: "Mr. Amrish V. Vyas.",
        contactNo: "9426504575"
    },
    {
        name: "Mr. Mukesh D. Patel ",
        contactNo: "9825904534"
    },
    {
        name: "Mr. Darshan Dave ",
        contactNo: "9727795577"
    },
    {
        name: "Mr. Piyush M. Patel",
        contactNo: "9898029654"
    },
    {
        name: "Mr. Jayesh D. Bhatt",
        contactNo: "9825095091"
    },
    {
        name: "Mr. Patel Shailesh Lakshmanbhai",
        contactNo: "9898630561"
    },
    {
        name: "Mr. Jikesh M. Patel",
        contactNo: "9904599241"
    },
    {
        name: "Mr. Nirav Biharibhai Shah",
        contactNo: "9328888712"
    },
    {
        name: "Mr. Vinodbhai Sankarbhai Patel",
        contactNo: "9824134949"
    },
    {
        name: "Mr. Niraj Kanakray Purohit",
        contactNo: "9825042659"
    },
    {
        name: "Mr. Samir Valjibhai Parmar",
        contactNo: "9898188014"
    },
    {
        name: "Mr. Jagat Karani.",
        contactNo: "9824011211"
    },
    {
        name: "Mr. Deepak R. Patel",
        contactNo: "9825005928"
    },
    {
        name: "Mr. Nilay. C. Desai",
        contactNo: "9825308625"
    },
    {
        name: "Mr. Vipul R. Patel",
        contactNo: "9825005955"
    },
    {
        name: "Mr. Hashmukhbhai K. Patel",
        contactNo: "9825175325"
    },
    {
        name: "Mr. Ganibhai A. Vadia",
        contactNo: "9825033937"
    },
    {
        name: "Late Mr. Nitin Patel",
        contactNo: "9979893785"
    },
    {
        name: "Mr. Patel Prakash Rambhai",
        contactNo: "9723810881"
    },
    {
        name: "Mr. Nilesh R. Patel",
        contactNo: "9099902909"
    },
    {
        name: "Mr. Dinesh Valjibhai Santoki",
        contactNo: "9974024264"
    },
    {
        name: "Mr. Shah Jayesh Amarchand",
        contactNo: "9924124042"
    },
    {
        name: "Mr. Patel Dahyabhai V.",
        contactNo: "9824274157"
    },
    {
        name: "Mr. Ghansyam Patel",
        contactNo: "9426087544"
    },
    {
        name: "Mr. Jayant Bhailalbhai Sheladiya",
        contactNo: "9824177411"
    },
    {
        name: "Mr. Rajesh L. Prajapati",
        contactNo: "9924109958"
    },
    {
        name: "Mr. Patel Jashu Mansukhbhai",
        contactNo: "9924127383"
    },
    {
        name: "Mr. Mehulkumar Pravinchandra Doshi ",
        contactNo: "9924127382"
    },
    {
        name: "Mr. Patel Kishorbhai Kantilal",
        contactNo: "9714390090"
    },
    {
        name: "Mr. Kiritkumar Babulal Soni",
        contactNo: "9825005927"
    },
    {
        name: "Mr. Pankaj Biharibhai Shah",
        contactNo: "9374033451"
    },
    {
        name: "Mr. Ramesh V. Prajapati",
        contactNo: "9998727743"
    },
    {
        name: "Mr. Patel Bipinbhai Vitthalbhai",
        contactNo: "9825023530"
    },
    {
        name: "Mr. Sunilkumar Kantilal Jhoshi",
        contactNo: "9426079306"
    },
    {
        name: "Mr. Sunil Balvantray Patel",
        contactNo: "9426079305"
    },
    {
        name: "Mr. Shah Sandeep Vipinchandra ",
        contactNo: "9825024546"
    },
    {
        name: "Mr. Hrishikesh H. Bhatt",
        contactNo: "9825285800"
    },
    {
        name: "Mr. Sandip B. Shah ",
        contactNo: "98253 58456"
    },
    {
        name: "Mr. Jaysukh T. Sapariya ",
        contactNo: "9925234139"
    },
    {
        name: "Mr. Rakesh H. Shah",
        contactNo: "9426009520"
    },
    {
        name: "Mr. V. D. Patel",
        contactNo: "9898271112"
    },
    {
        name: "Mr. Patel Dineshkumar Madhavlal",
        contactNo: "9824077409"
    },
    {
        name: "Mr. Ramesh Hemchanddas Panchal",
        contactNo: "9328872689"
    },
    {
        name: "Mr. Rakesh Haribhai Upadyay",
        contactNo: "9824301411"
    },
    {
        name: "Mr. Manish L. Patel",
        contactNo: "9825149539"
    },
    {
        name: "Mr. Rakesh Punjiram Joshi",
        contactNo: "9426060448"
    },
    {
        name: "Mr. Manoj Harivadan Lekinvala",
        contactNo: "9426009697"
    },
    {
        name: "Mr. Patel Mukeshbhai Vitthalbhai",
        contactNo: "9825243500"
    },
    {
        name: "Mr. Patel Pravinbhai Maganlal",
        contactNo: "9426401673"
    },
    {
        name: "Mr. Sunny Chandubhai Patel ",
        contactNo: "9426022850"
    },
    {
        name: "Mrs. Patel Ranjanben Gautambhai ",
        contactNo: "9327024234"
    },
    {
        name: "Mr. Patel Rajendrakumar Ishawarlal",
        contactNo: "9879206030"
    },
    {
        name: "Mr. Pravinkumar Ambalal Patel",
        contactNo: "9825410100"
    },
    {
        name: "Mr. Dashrathbhai Shivabhai Chaudhari",
        contactNo: "9426010875"
    },
    {
        name: "Mr. Miteshbhai Patel",
        contactNo: "9909393717"
    },
    {
        name: "Mr. Jitubhai Soni",
        contactNo: "9825005927"
    },
    {
        name: "Mr. Pankajbhai B. Patel",
        contactNo: "9879855348"
    },
    {
        name: "Mr. Jayantibhi S. Dabhi",
        contactNo: "9727795574"
    },
    {
        name: "Mr. Dr. Prakshbhai P. Joshi",
        contactNo: "9898560685"
    },
    {
        name: "Mr. Ramesh A. Patel",
        contactNo: "9825082595"
    },
    {
        name: "Mr. Patel Amrutbhai Becharbhai",
        contactNo: "9824016047"
    },
    {
        name: "Mr. Snehal Prafulchandra Suthar ",
        contactNo: "9924101571"
    },
    {
        name: "Mr. Ambalal Maganbhai Patel",
        contactNo: "9327048568"
    },
    {
        name: "Mr. Kashyap Harshadbhai Amin",
        contactNo: "9824009963"
    },
    {
        name: "Mr. Shah Bankim Balubhai",
        contactNo: "9825888679"
    },
    {
        name: "Mr. Imtiyaz Ibrahim Qureshi",
        contactNo: "9824367329"
    },
    {
        name: "Mr. Amrutbhai A. Prajapati",
        contactNo: "9824613449"
    },
    {
        name: "Mr. Nimesh Manuprsed Raval",
        contactNo: "9879528702"
    },
    {
        name: "Mr. Patel Jayesh Natwarlal",
        contactNo: "9574872000"
    },
    {
        name: "Mr. Prajapati Mahendra Mafatlal",
        contactNo: "9825037797"
    },
    {
        name: "Mr. Kiran Dharmendrabhai Patel",
        contactNo: "9725502416"
    },
    {
        name: "Mr. Prajapati Suresh Chandulal",
        contactNo: "9825062537"
    },
    {
        name: "Mr. Patel Ashish H.",
        contactNo: "9978910567"
    },
    {
        name: "Mr. Shri Bankim Joshi",
        contactNo: "9825867305"
    },
    {
        name: "Mr. Gaurang N. Patel",
        contactNo: "9825867305"
    },
    {
        name: "Mr. Priyanath K. Patel",
        contactNo: "9824613488"
    },
    {
        name: "Mr. Narendra R. Patel",
        contactNo: "9724506474"
    },
    {
        name: "Mr. Rajnikant R. Patel",
        contactNo: "9825305376"
    },
    {
        name: "Mr. Hiren A. Patel",
        contactNo: "9428042353"
    },
    {
        name: "Mr. Sureshbhai Patel",
        contactNo: "9426181535"
    },
    {
        name: "Mr. Dharmendra S. Darji",
        contactNo: "9825447940"
    },
    {
        name: "Mr. Dilip A. Jani",
        contactNo: "9825847796"
    },
    {
        name: "Mr. Ketankumar Dineshbhai Patel",
        contactNo: "9978910839"
    },
    {
        name: "Mr. Kaushik D. Visavadia",
        contactNo: "9601516217"
    },
    {
        name: "Mr. Kaushik A. Patel",
        contactNo: "9904277777"
    },
    {
        name: "Mr. Nilesh L. Prajapati",
        contactNo: "9898757830"
    },
    {
        name: "Mr. Mansukh Santoki",
        contactNo: "9426516242"
    },
    {
        name: "Mr. Nilesh N. Baldha ",
        contactNo: "9998880755"
    },
    {
        name: "Mr. Vikram B. Maheta",
        contactNo: "9898313761"
    },
    {
        name: "Mr. Rasik M. Patel ",
        contactNo: "9428411770"
    },
    {
        name: "Mr. Pankaj H. Patel",
        contactNo: "9427320073"
    },
    {
        name: "Mr. Suresh N. Patel",
        contactNo: "9825063980"
    },
    {
        name: "Mr. Parsotam V. Patel ",
        contactNo: "9825522276"
    },
    {
        name: "Mr. Rishabh N. Patel",
        contactNo: "9825023455"
    },
    {
        name: "Mr. JIignesh P. Shah",
        contactNo: "9824060075"
    },
    {
        name: "Mr. Payank J. Patel ",
        contactNo: "9825082199"
    },
    {
        name: "Mr. Dinesh K. Patel",
        contactNo: "9825116505"
    },
    {
        name: "Mr. Baldev S. Patel ",
        contactNo: "9825382071"
    },
    {
        name: "Mr. Jagdish S Patel ",
        contactNo: "9825075831"
    },
    {
        name: "Mr. Yogesh J. Patel ",
        contactNo: "9375155048"
    },
    {
        name: "Mr. Dilipkumar Visanjibhai Patel",
        contactNo: "9825433766"
    },
    {
        name: "Mr. Nishith shirishbhai Desai ",
        contactNo: "825253070"
    },
    {
        name: "Mr. Ashok Madhavlal Patel",
        contactNo: "9825025133"
    },
    {
        name: "Mr. Dineshkumar Manilal Patel",
        contactNo: "9825257397"
    },
    {
        name: "Mr. Mukesh K. Patel",
        contactNo: "9825521495"
    },
    {
        name: "Mr. Hiren Sureshbhai Patel ",
        contactNo: "9909930000"
    },
    {
        name: "Mr. Kairav Priyvadan Shah",
        contactNo: "9898125456"
    },
    {
        name: "Mr. Bhavesh Shantilal Chauhan",
        contactNo: "9825051256"
    },
    {
        name: "Mr. Maheshkumar Lalbhai Patel",
        contactNo: "9099859636"
    },
    {
        name: "Mr. Amitbhai Sakalchand Patel",
        contactNo: "9879554394"
    },
    {
        name: "Mr. Prakashbhai Nagajibhai Shingala",
        contactNo: "9879605575"
    },
    {
        name: "Mr. Pankajbhai Arvindbhai Patel",
        contactNo: "9998258941"
    },
    {
        name: "Mr. Jitubhai Bhalabhai Prajapati",
        contactNo: "9824040417"
    },
    {
        name: "Mr. Narendra Dhirajlal Gohil",
        contactNo: "9978900566"
    },
    {
        name: "Mr. Pramukhbhai Dayabhai Patel",
        contactNo: "9825020024"
    },
    {
        name: "Mr. Dilipbhai Bhudarbhai Patel",
        contactNo: "9824380888"
    },
    {
        name: "Mr. Gansyam Baldevbhai Patel",
        contactNo: "9426181706"
    },
    {
        name: "Late Mr. Rameshbhai Ranchhodbhai Patel",
        contactNo: "9979851786"
    },
    {
        name: "Mr. Vishnubhai Gandabhai Patel",
        contactNo: "9825061463"
    },
    {
        name: "Mr. Rameshbhai Revabhai Patel",
        contactNo: "9825280655"
    },
    {
        name: "Mr. Pravinkumar Dayabhai Patel",
        contactNo: "9426171394"
    },
    {
        name: "Mr. Himansu Rashiklal Raval",
        contactNo: "9824256269"
    },
    {
        name: "Mr. Nilesh Hashmukhlal Modi",
        contactNo: "9825045428"
    },
    {
        name: "Mr. Kamleshbhai Ambalal Patel",
        contactNo: "9825009514"
    },
    {
        name: "Mr. Jitenra Somabhai Patel",
        contactNo: "9825014150"
    },
    {
        name: "Mr. Vinodbhai Samalbhai Patel",
        contactNo: "9825073700"
    },
    {
        name: "Mr. Rajesh Chhaganlal Patel",
        contactNo: "9825072138"
    },
    {
        name: "Mr. Vijaybhai Naranbhai Patel",
        contactNo: "9824538519"
    },
    {
        name: "Mr. Kaushik Chandulal Patel",
        contactNo: "9824055177"
    },
    {
        name: "Mr. Mahenrabhai Ishvarbhai Patel",
        contactNo: "9227240547"
    },
    {
        name: "Mr. Mukeshbhai Sankarbhai Chaudhari",
        contactNo: "9227240547"
    },
    {
        name: "Mr. Kanubhai Dayabhai Chaudhari",
        contactNo: "9426394900"
    },
    {
        name: "Mr. Rameshbhai Laxmanbhai Raval",
        contactNo: "9898065283"
    },
    {
        name: "Mr. Natvarbhai Kalidas Patel",
        contactNo: "9898170026"
    },
    {
        name: "Mr. Jasvantbhai Kantibhai Patel",
        contactNo: "9825071647"
    },
    {
        name: "Mr. Kanubhai vithaldas Patel",
        contactNo: "9426022444"
    },
    {
        name: "Mr. Anilkumar Rameshbhai Patel",
        contactNo: "9879843640"
    },
    {
        name: "Mr. Jasvantbhai Shakarchand Patel",
        contactNo: "9426044442"
    },
    {
        name: "Shri Ketanbhai Hirabhai Dhalu",
        contactNo: "9825013331"
    },
    {
        name: "Mr. Maheshkumar Ratibhai Patel",
        contactNo: "9825069006"
    },
    {
        name: "Mr. Harsadbhai Santibhai Patel",
        contactNo: "9727768497"
    },
    {
        name: "Mr. Rajeshbhai Dhudabhai Patel",
        contactNo: "9825801689"
    },
    {
        name: "Mr. Dilip I. Patel",
        contactNo: "9825023024"
    },
    {
        name: "Mr. Jayesh B. Patel",
        contactNo: "9825339987"
    },
    {
        name: "Mr. Ramesh M. Prajapati",
        contactNo: "9687603093"
    },
    {
        name: "Mr. Vikrant Purohit",
        contactNo: "9825506929"
    },
    {
        name: "Mr. Rakesh Prajapati",
        contactNo: "9879130651"
    },
    {
        name: "Mr. Suresh K. Patel",
        contactNo: "9974079650"
    },
    {
        name: "Ronak Joshi",
        contactNo: "9825739588"
    },
    {
        name: "Pankaj Kotak",
        contactNo: "9909027446"
    },
    {
        name: "Mr. Rakesh K. Patel",
        contactNo: "9879719575"
    },
    {
        name: "Mr. Chaitanya Babulal Patel",
        contactNo: "9825018039"
    },
    {
        name: "Mr. Hasmukh Shankarlal Patel",
        contactNo: "9737124032"
    },
    {
        name: "Mr. Nareshbhai Harishbhai Patel",
        contactNo: "9825307242"
    },
    {
        name: "Mr. Ashish Dilip Patel",
        contactNo: "9825050200"
    },
    {
        name: "Mr. Jignesh Vipulbhai Patel",
        contactNo: "9925561301"
    },
    {
        name: "Mr. Gaurang Dashrathbhai Patel",
        contactNo: "9824098251"
    },
    {
        name: "Mr. Minesh Bhogilal Patel ( Yogesh )",
        contactNo: "9426510183"
    },
    {
        name: "Mr. Saiyam Vishwabandhu Patel",
        contactNo: "9825749292"
    },
    {
        name: "Mr. Arunkumar Manjibhai Bambhantia",
        contactNo: "98256 03321"
    },
    {
        name: "Mr.Jiten Dineshbhai Patel",
        contactNo: "9825098520"
    },
    {
        name: "Mr. Shyam Ranchhodlal Ganatra",
        contactNo: "9925036687"
    },
    {
        name: "Mr. Hareshkumar Ravjibhai Vasani",
        contactNo: "9825064085"
    },
    {
        name: "Mr. Chirag Vikrambhai Patel",
        contactNo: "9429999993"
    },
    {
        name: "Mr. Arnitkumar Hargovinddas Patel",
        contactNo: "9925042567"
    },
    {
        name: "Mr. Vishnubhai Chandubhai Patel",
        contactNo: "9909324058"
    },
    {
        name: "Mr. Kinnar S. Jhaveri",
        contactNo: "9824021135"
    },
    {
        name: "Mr. Jashrajbhai J. Chaudhari",
        contactNo: "9712734821"
    },
    {
        name: "Mr. Amit M. Halani",
        contactNo: "7359104293"
    },
    {
        name: "Mr. Kshitij Dilipkumar Patel",
        contactNo: "9824539571"
    },
    {
        name: "Mr. Kaushikbhai Sankalchand Patel",
        contactNo: "9426022777"
    },
    {
        name: "Mr. Ghanshyam Bachubhai Finviya",
        contactNo: "9099855490"
    },
    {
        name: "Mr. Balbhadrasinh Chudasama",
        contactNo: "9687844999"
    },
    {
        name: "Mr. Vinubhai Mohanbhai Zalavadiya",
        contactNo: "9898576839"
    },
    {
        name: "Mr. Mitesh Kanubhai Patel",
        contactNo: "9998122444"
    },
    {
        name: "Kiranben Vinodchandra Tanna",
        contactNo: "9328893737"
    },
    {
        name: "Mr. Sujal Harshadbhai Modi",
        contactNo: "9824513101"
    },
    {
        name: "Mr. Himanshu Narayanbhai Patel",
        contactNo: "9825135147"
    },
    {
        name: " Mr. Jigneshkumar Madhubhai Patel ",
        contactNo: "9898039026"
    },
    {
        name: "Mr. Vipulkumar Amrutlal Patel",
        contactNo: "7878723334"
    },
    {
        name: "Mr. Himanshu Naranbhai Patel",
        contactNo: "9825135147"
    },
    {
        name: "Mr. Rajnikant Baldevbhai Patel",
        contactNo: "8490063811"
    },
    {
        name: "Mr. Rohitbhai Bhikhabhai Patel",
        contactNo: "9925228056"
    },
    {
        name: "Mr. Tushar Babubhai Sheladiya",
        contactNo: "9904499988"
    },
    {
        name: "Mr. Kamleshbhai Varvabhai Patel",
        contactNo: "9825060344"
    },
    {
        name: "Mr. Chetankumar Purushottamdas Patel",
        contactNo: "9898999651"
    },
    {
        name: "Mr. Tarun S. Varma",
        contactNo: "9825082464",
    },
    {
        name: "Mr. Pareshkumar Shankarbhai Chaudhary",
        contactNo: "9377812308",
    },
    {
        name: "Mr. Sunilkumar Chandrakantbhai Patel",
        contactNo: "9727551001",
    },
    {
        name: "Mr. Nisarg H. Patel",
        contactNo: "9898544877",
    },
    {
        name: "Mr. Nimesh Dahyabhai Chaudhary",
        contactNo: "9712101100",
    },
    {
        name: "Mr. Maheshkumar Nathubhai Chaudhary",
        contactNo: "9426033333",
    },
    {
        name: "Mr. Manishbhai Kantibhai Patel",
        contactNo: "9898625050",
    },
    {
        name: "Mr. Lalabhai Hargovandas Patel",
        contactNo: "9429061783",
    },
    {
        name: "Mr. Brijeshkumar Rameshbhai Patel",
        contactNo: "9925463702",
    },
    {
        name: "Mr. Romil Rasikbhai Patel",
        contactNo: "9081234994",
    },
    {
        name: "Mr. Tejas Kamleshkumar Patel",
        contactNo: "8511151577",
    },
    {
        name: "Mr. Bharatbhai Kiritbhai Gajjar",
        contactNo: "8490946221",
    },
    {
        name: "Mr. Vishnubhai Nathabhai Patel",
        contactNo: "9825787039",
    },
    {
        name: "Mr. Utpal Pankajbhai Patel",
        contactNo: "7575089694",
    },
    {
        name: "Mr. Sachin Sureshbhai Patel",
        contactNo: "9714467950",
    },
    {
        name: "Mr. Rakesh Dashrathlal Patel",
        contactNo: "9725011117",
    },
    {
        name: "Mr. Harshad Chandubhai Patel",
        contactNo: "9824032133",
    },
    {
        name: "Mr. Govind J Changlani",
        contactNo: "9825036466",
    },
    {
        name: "Mr. Parth Jayantibhai Patel",
        contactNo: "9825022559",
    },
    {
        name: "Mr. Ankit Kantibhai Patel",
        contactNo: "9825014388",
    },
    {
        name: "Mr. Minesh Bhagvatbhai Patel",
        contactNo: "9106872772",
    },
    {
        name: "Mr. Hiral Brahmbhatt",
        contactNo: "9924239099",
    },
    {
        name: "Mr. Vishnu B Patel",
        contactNo: "9426544425",
    },
    {
        name: "Mr. Rushil Bhaveshbhai Thakkar",
        contactNo: "9081986999",
    },
    {
        name: "Mr. Dhaval Arvindbhai Barolia",
        contactNo: "9974777790",
    },
    {
        name: "Mr. Ravi Arvindbhai Barolia",
        contactNo: "8460063699",
    },
    {
        name: "Mr. Nirav Vrajlal Barolia",
        contactNo: "9898740885",
    },
    {
        name: "Mr. Chintan Vrajlal Barolia",
        contactNo: "9879053092",
    },
    {
        name: "Mr. Sureshkumar Ramjibhai Barolia",
        contactNo: "9879143995",
    },
    {
        name: "Mr. Ashish Dalpatbhai Barolia",
        contactNo: "9998777701",
    },
    {
        name: "Mr. Akash Dalpatbhai Barolia",
        contactNo: "7621847008",
    },
    {
        name: "Mr. Brinjal Chandrakantbhai Patel",
        contactNo: "9998352230",
    },
    {
        name: "Mr. Ghanshyam Kalabhai Patel",
        contactNo: "9825458804",
    },
    {
        name: "Mr. Patel Divyesh Harshadbhai",
        contactNo: "9714939499",
    },
    {
        name: "Mr. Nimeshkumar Manubhai Patel",
        contactNo: "9427801897",
    },
    {
        name: "Mr. Saurabh Bhikhabhai Patel",
        contactNo: "9426060160",
    },
    {
        name: "Mr. Patel Rahulkumar Mahendrakumar",
        contactNo: "9016629788",
    },
    {
        name: "Mr. Patel Maheshkumar Becharbhai",
        contactNo: "9510376264/8690762177",
    },
    {
        name: "Mr. Patel Bharatbhai Gandabhai",
        contactNo: "9979099715/9979099715",
    },
    {
        name: "Mr. Soni Rakeshkumar Chandulal",
        contactNo: "7600941990",
    },
    {
        name: "Mr. Patel Prakashbhai Naryanabhai",
        contactNo: "9825032921",
    },
    {
        name: "Mr. Sandipbhai Ishwarbhai Patel",
        contactNo: "9824098501",
    },
    {
        name: "Mr. Patel Rachit Prakashbhai",
        contactNo: "88866686006",
    },
    {
        name: "Mr. Aakash Sharad Vyas",
        contactNo: "9998688889",
    },
    {
        name: "Mr. Sharad Bachubhai Vyas",
        contactNo: "9825332923",
    },
    {
        name: "Mr. Patel Devangkumar Shankarlal",
        contactNo: "9714157976",
    },
    {
        name: "Mr. Shravan Kirankumar Patel",
        contactNo: "9427325207",
    },
    {
        name: "Mr. Patel Kiritkumar Shankarlal",
        contactNo: "9687604597",
    },
    {
        name: "Mr. Patel Devang Prafulbhai",
        contactNo: "9825306144",
    },
    {
        name: "Mr. Jigneshkumar Manubhai Vora",
        contactNo: "9898289676",
    },
    {
        name: "Mr. Arpit Santoki 7434006001",
        contactNo: " ",
    },
    {
        name: "Mr. Anand Santoki 9265257104",
        contactNo: "7434003008",
    },
    {
        name: "Mr. Anshul Jashu Patel",
        contactNo: "7043158100",
    },
    {
        name: "Mr. Jugal Jashu Patel",
        contactNo: "9033234763",
    },
    {
        name: "Mr. Manan Rishikesh",
        contactNo: "8238044898"
    },
    {
        name: "Mr. Vishnu Chaitanyabhai Patel",
        contactNo: "9825021186",
    },
    {
        name: "Mr. Patel Amrat Nathabhai",
        contactNo: "8141230000",
    },
    {
        name: "Mr. Hiteshkumar Baldevbhai Chaudhary",
        contactNo: "9904484315",
    },
    {
        name: "Mr. Jayesh Ishwarbhai Patel",
        contactNo: "9825090890",
    },
    {
        name: "Mr. Bhavik Jayantibhai Sheladiya",
        contactNo: "9898729729",
    },
    {
        name: "Mr. Raahi Dilip Patel",
        contactNo: "9726531347",
    },
    {
        name: "Mr. Akash Bharatkumat Gajjar",
        contactNo: "9099034875",
    },
    {
        name: "Mr. Nisarg Ashishbhai Patel",
        contactNo: "9879667999",
    },
    {
        name: "Mr. Harsh Nareshbhai Patel",
        contactNo: "9825333376",
    },
    {
        name: "Mr. Smit Sureshbhai Patel",
        contactNo: "9558818904",
    },
    {
        name: "Mr. Chetankumar Jayantibhai Patel",
        contactNo: "8886116644/880662008",
    },
    {
        name: "Mr. Jaiminbhai Mafatlal Patel ",
        contactNo: "9825894371/9825135371"
    },
    {
        name: "Mr. Nandish Nilay Desai",
        contactNo: "07923600158/8980947474"
    },
    {
        name: "Mr. Arjav Bankim Joshi",
        contactNo: "07923600158/9512023355"
    },
    {
        name: "Mr. Patel Hasmukhbhai kacharabhai",
        contactNo: "9898631601/9898631601"
    },
    {
        name: "Mr. Patel Ritesh Dahyabhai",
        contactNo: "9909709302"
    },
    {
        name: "Mr. Chaudhari Vipulkumar Babulal",
        contactNo: "9979550636"
    },
    {
        name: "Mr. Patel Pravinkumar Mayurbhai",
        contactNo: "9426006689"
    },
    {
        name: "Mr. Patel Adhish Ashish",
        contactNo: "9909005214"
    },
    {
        name: "Mr. Ravi Jankkumar Brhmabhatt",
        contactNo: "9879572336"
    },
    {
        name: "Mr. Dipesh Ramehbhai Patel",
        contactNo: "9978503000"
    },
    {
        name: "Mr. Dholariya Sureshkumar Chhaganlal",
        contactNo: "9925204291"
    },
    {
        name: "Mr. Yatin Rameshbhai Paghadal",
        contactNo: "7600800477"
    },
]

export const CommitteeMemberList: Array<CommitteeMemberType> = [
    {
        name: "Mr. Jayant B. Sheldiya",
        post: "Chairman",
        contactNo: "9824177411",
    },
    {
        name: "Mr. Kiran D. Patel",
        post: "President",
        contactNo: "9725502416",
    },
    {
        name: "Mr. Jashu M. Patel",
        post: "Vice President",
        contactNo: "9924127383",
    },
    {
        name: "Mr. Mukesh S. Chaudhari",
        post: "Vice President",
        contactNo: "9426367167",
    },
    {
        name: "Mr. S.K. Patel",
        post: "Hon. Secretary",
        contactNo: "9974079650",
    },
    {
        name: "Mr. Sandip B. Shah",
        post: "Treasurer",
        contactNo: "9825358456",
    },
    {
        name: "Mr. Rajendra I. Patel",
        post: "Jt. Secretary",
        contactNo: "8980007481",
    },
    {
        name: "Mr. Hrishikesh H. Bhatt",
        post: "Jt. Secretary",
        contactNo: "9825285800",
    },
    {
        name: "Mr. Dilip B. Patel",
        post: "Executive Member",
        contactNo: "9824380888",
    },
    {
        name: "Mr. Dinesh M. Patel",
        post: "Executive Member",
        contactNo: "9825257397",
    },
    {
        name: "Mr. Jikesh M. Patel",
        post: "Executive Member",
        contactNo: "9099902907",
    },
    {
        name: "Mr. Haresh R. Vasani",
        post: "Executive Member",
        contactNo: "9825064085",
    },
    {
        name: "Mr. Vikrant V. Purohit",
        post: "Executive Member",
        contactNo: "9825506929",
    },
    {
        name: "Mr. Lalabhai H. Patel",
        post: "Executive Member",
        contactNo: "9429061783",
    },
    {
        name: "Mr. Vishnu C. Patel",
        post: "Executive Member",
        contactNo: "9909324058",
    },
    {
        name: "Mr. Niraj K. Purohit",
        post: "Executive Member",
        contactNo: "9825042659",
    },
    {
        name: "Mr. Dilip I. Patel",
        post: "Executive Member",
        contactNo: "9825023024",
    },
    {
        name: "Mr. Mahesh L. Patel",
        post: "Executive Member",
        contactNo: "9099859636",
    },
    {
        name: "Mr. Nilay C. Desai",
        post: "Group Advisory Committee",
        contactNo: "9825308625",
    },
    {
        name: "Mr. Mukeshbhai D. Patel",
        post: "Group Advisory Committee",
        contactNo: "9825904534",
    },
    {
        name: "Mr. Nilesh R. Patel",
        post: "Group Advisory Committee",
        contactNo: "9099902909",
    },
    {
        name: "Mr. Pramukhbhai D. Patel",
        post: "Group Advisory Committee",
        contactNo: "9825020024",
    },
    {
        name: "Mr. Pravin A. Patel",
        post: "Group Advisory Committee",
        contactNo: "9825410100",
    },
    {
        name: "Mr. Sureshbhai N. Patel",
        post: "Group Advisory Committee",
        contactNo: "9099902901",
    },
]

export const PastPresidentList: Array<PastPresidentType> = [
    {
        name: "Mr. Ganibhai A Vadia",
        period: "1993-1997"
    }, {
        name: "Mr. Hashmukhbhai K Patel",
        period: "1997-1999"
    }, {
        name: "Mr. Vipul R Patel",
        period: "1999-2001"
    }, {
        name: "Mr. Nilay C Desai",
        period: "2001-2003"
    }, {
        name: "Mr Sharad B Vyas",
        period: "2003-2005"
    }, {
        name: "Mr. Deepak R Patel",
        period: "2005-2006"
    }, {
        name: "Mr. Jagat H Karani",
        period: "2006-2008"
    }, {
        name: "Mr. Amrish V Vyas",
        period: "2008-2010"
    }, {
        name: "Mr. Mukesh D Patel ",
        period: "2010-2012"
    }, {
        name: "Mr. Nilesh R Patel",
        period: "2012-2014"
    }, {
        name: "Mr. Jayant B Sheladiya",
        period: "2014-2015"
    }, {
        name: "Mr. Prakash R Patel",
        period: "2014-2015"
    }, {
        name: "Mr. Pramukh D Patel",
        period: "2015-2017"
    }, {
        name: "Mr. Pravin A Patel",
        period: "2017-2019"
    }, {
        name: "Mr. Sureshbhai N. Patel",
        period: "2019-2022"
    }
]