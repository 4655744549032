import { styled } from "@mui/material";
import { useState } from "react";
import Header from "./Header";
import SideMenu from "./SideMenu";
import { Outlet } from 'react-router-dom';
import Footer from "./Footer";


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(
    ({ theme, open }) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: `${drawerWidth}px`,
        }),
    })
);

const HomeWrapper = () => {

    const [openWrapper, setOpenWrapper] = useState(true);

    return (
        <>
            <Header drawerOpen={openWrapper} onToggle={setOpenWrapper} />
            <SideMenu open={openWrapper} onToggle={setOpenWrapper} />
            <Main className="main-wrapper" open={openWrapper}>
                <Outlet />
                <Footer />
            </Main>
        </>
    )

}

export default HomeWrapper;