import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { CommitteeMemberList, MemberList, PastPresidentList } from "../Assets/Constants";
import { CommitteeMemberType, MemberType, PastPresidentType } from '../Assets/Interfaces';
import { TextField, Typography } from '@mui/material';
import { useState } from 'react';
import styled from '@mui/material/styles/styled';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


const Members = () => {

    const [activeTab, setActiveTab] = useState(0);
    const [filterMembers, setFilterMembers] = useState("");


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setFilterMembers("");
        setActiveTab(newValue);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#006699",
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    return (
        <div className="container">
            <Box sx={{ width: '100%' }}>



                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={activeTab} onChange={handleChange} variant="scrollable">
                        <Tab label="All Members" {...a11yProps(0)} />
                        <Tab label="Committee Members" {...a11yProps(1)} />
                        <Tab label="Past Presidents" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={activeTab} index={0}>
                    <Box>
                        <TextField
                            autoFocus={true}
                            label="Search Member"
                            fullWidth
                            value={filterMembers}
                            onChange={(e) => setFilterMembers(e.target.value)}
                        />
                        <br />
                        <br />

                        <TableContainer component={Paper} sx={{ maxHeight: 440 }}>

                            <Table sx={{ minWidth: 650 }} stickyHeader size="small" aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell>FLM Number</StyledTableCell>
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>Contact Number</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        MemberList
                                            .filter((member: MemberType) =>
                                                member.name.toLowerCase().includes(filterMembers.toLowerCase()) ||
                                                member.contactNo.toLowerCase().includes(filterMembers.toLowerCase())
                                            )
                                            .map((member: MemberType, index: number) => (
                                                <StyledTableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <StyledTableCell component="th" scope="row">{index + 1}</StyledTableCell>
                                                    <StyledTableCell >
                                                        {member.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell>{member.contactNo}</StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <TextField
                        autoFocus={true}
                        label="Search Committee"
                        fullWidth
                        value={filterMembers}
                        onChange={(e) => setFilterMembers(e.target.value)}
                    />
                    <br />
                    <br />
                    <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
                        <Table sx={{ minWidth: 650 }} stickyHeader size="small" aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>FLM Number</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Post</StyledTableCell>
                                    <StyledTableCell>Contact Number</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    CommitteeMemberList
                                        .filter((member: CommitteeMemberType) =>
                                            member.name.toLowerCase().includes(filterMembers.toLowerCase()) ||
                                            member.contactNo.toLowerCase().includes(filterMembers.toLowerCase()) ||
                                            member.post.toLowerCase().includes(filterMembers.toLowerCase())
                                        )
                                        .map((member: CommitteeMemberType, index: number) => (
                                            <StyledTableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <StyledTableCell component="th" scope="row">{index + 1}</StyledTableCell>
                                                <StyledTableCell>{member.name}</StyledTableCell>
                                                <StyledTableCell>{member.post}</StyledTableCell>
                                                <StyledTableCell>{member.contactNo}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                    <TextField
                        autoFocus={true}
                        label="Search President"
                        fullWidth
                        value={filterMembers}
                        onChange={(e) => setFilterMembers(e.target.value)}
                    />
                    <br />
                    <br />
                    <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
                        <Table sx={{ minWidth: 650 }} stickyHeader size="small" aria-label="simple table">
                            <TableHead>
                                <StyledTableCell>Number</StyledTableCell>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Period</StyledTableCell>
                            </TableHead>
                            <TableBody>
                                {
                                    PastPresidentList
                                        .filter((member: PastPresidentType) =>
                                            member.name.toLowerCase().includes(filterMembers.toLowerCase()) ||
                                            member.period.toLowerCase().includes(filterMembers.toLowerCase())
                                        )
                                        .map((member: PastPresidentType, index: number) => (
                                            <StyledTableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <StyledTableCell component="th" scope="row">{index + 1}</StyledTableCell>
                                                <StyledTableCell>{member.name}</StyledTableCell>
                                                <StyledTableCell>{member.period}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            </Box>
        </div>
    );
}

export default Members;