import { Box, Grid, Typography, Snackbar, Alert } from "@mui/material";
import HeaderImg from '../Assets/Images/PropertyFest/header.jpg'
import TitleImg from '../Assets/Images/PropertyFest/title.png'
import ProjectDetailsImg from '../Assets/Images/PropertyFest/project-details.png'
import BgMap from '../Assets/Images/PropertyFest/bg-map.png'
import PrizesImg from '../Assets/Images/PropertyFest/prizes.png'
import CompanyGridImg from '../Assets/Images/PropertyFest/companies-2.jpg'
import CredaiLogo from '../Assets/Images/PropertyFest/logo-white.png'
import RegisterNowImg from '../Assets/Images/PropertyFest/register-now.png'
import FacebookImg from '../Assets/Images/PropertyFest/ic-facebook.png'
import LinkedInImg from '../Assets/Images/PropertyFest/ic-linkedin.png'
import InstagramImg from '../Assets/Images/PropertyFest/ic-instagram.png'
import RegistrationQRCode from '../Assets/Images/PropertyFest/registration-qr-code.jpg'
import LabelQRCode from '../Assets/Images/PropertyFest/label-qr-code.jpg'
import { useState } from "react";
import { Link } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';


const PropertyFest = () => {

    const [openToast, setOpenToast] = useState(false);
    const smallDeviceMatched = useMediaQuery('(max-width:1000px)');

    return (
        <Box>
            <img width="100%" src={HeaderImg} alt="" />
            <Box className="garden-wrapper">
                <Grid container justifyContent="center">
                    <Grid item className="info-container" container alignItems="center" flexDirection={"column"}>
                        <Grid item>
                            <img src={TitleImg} alt="" width={"100%"} />
                        </Grid>
                        <Grid item marginTop={5}>
                            <img src={ProjectDetailsImg} alt="" width={"100%"} />
                        </Grid>
                        <Grid item>
                            <a className="btn-register-now" href="https://www.evenuefy.com/dashboard/sharedT/q5Z2ko" target="_blank" >
                                <img src={RegisterNowImg} alt="" width={"70%"} />
                            </a>
                        </Grid>
                        <Grid item marginTop={4}>
                            <img src={PrizesImg} alt="" width={"100%"} />
                        </Grid>

                        <Grid item marginY={5}>
                            <img src={CompanyGridImg} alt="" width={"100%"} />
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item marginTop={-2}>
                    <img src={BgMap} alt="" width={"100%"} />
                </Grid>
                <Grid className="credai-details" container justifyContent="center" flexDirection="column">
                    <Grid className="qr-code-wrapper" item alignItems={"flex-end"} container flexDirection="column">
                        <img src={RegistrationQRCode} alt="" />
                        <img src={LabelQRCode} alt="" />
                    </Grid>
                    <Grid item container justifyContent={"space-between"} marginTop={smallDeviceMatched ? 10 : 0}>
                        <Grid item>
                            <Grid item>
                                <img src={CredaiLogo} alt="" />
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" color="#fff">CONFEDERATION OF REAL ESTATE DEVELOPERS ASSOCIATION OF INDIA - GANDHINAGAR</Typography>
                            </Grid>
                            <Grid item container flexDirection="row" alignItems="center" justifyContent={"space-between"} flexWrap={'nowrap'}>
                                <Grid item container gap={1} alignItems="center">
                                    <Typography variant="subtitle1" color="#fff">
                                        Visit here:
                                    </Typography>
                                    <Link to="/home">
                                        <Typography variant="caption" color="#fff">
                                            https://credaigandhinagar.com/home
                                        </Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container flexDirection="row" alignItems="center">
                        <Grid item xs={10}>
                            <hr />
                        </Grid>
                        <Grid item>
                            <a href="https://www.facebook.com/profile.php?id=100089493277108" target="_blank">
                                <img src={FacebookImg} className="ic-social-media" />
                            </a>
                        </Grid>
                        <Grid item>
                            <a href="https://www.instagram.com/credaigandhinagar/" target="_blank">
                                <img src={InstagramImg} className="ic-social-media" />
                            </a>
                        </Grid>
                        <Grid item>
                            <a href="https://www.linkedin.com/company/90858865/admin/" target="_blank">
                                <img src={LinkedInImg} className="ic-social-media" />
                            </a>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1" color="#fff">407, Shreerang Heights, Opp. Bhaijipura Patiya,</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1" color="#fff">Gandhinagar - Koba Highway, Kudasan, Gandhinagar - 382421 - Gujarat.</Typography>
                    </Grid>
                </Grid>
            </Box>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openToast}
                autoHideDuration={6000}
                onClose={() => setOpenToast(false)}
                message="Registration will be available soon!!"
            >
                <Alert onClose={() => setOpenToast(false)} severity="error" sx={{ width: '100%' }}>
                    Registration will be available soon!!
                </Alert>
            </Snackbar>
        </Box>

    );
}

export default PropertyFest;