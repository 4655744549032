import React, { useEffect, useState } from 'react';
import { styled, Theme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CSSObject } from '@emotion/react';
import { HeaderMenuList, SideMenuList } from '../Assets/Constants';
import { Link, useLocation } from 'react-router-dom';


interface PropTypes {
    open: boolean;
    onToggle: (close: boolean) => void
}

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    marginTop: '60px',
    width: drawerWidth,
    border: "none",
    backgroundColor: '#e6f7ff',
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
})

const closedMixin = (theme: Theme): CSSObject => ({
    marginTop: '60px',
    width: 0,
    border: "none",
    backgroundColor: '#e6f7ff',
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.leavingScreen,
    }),
})


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
            marginTop: '0px',
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
            marginTop: '0px',
        }),
    })
);

const SideMenu = (props: PropTypes) => {

    const location = useLocation();
    const smallScreenMatch = useMediaQuery('(max-width: 750px)');

    return (
        <Drawer className="sideMenu-wrapper" variant="permanent" open={props.open}>
            {
                smallScreenMatch && HeaderMenuList.map(menu => (
                    <Link className={`menu-item ${location.pathname === menu.link ? 'active' : ''}`} to={menu.link} onClick={() => smallScreenMatch && props.onToggle(false)}>
                        <span>{menu.label}</span>
                    </Link>
                ))
            }
            {smallScreenMatch &&
                <Divider />
            }
            {
                SideMenuList.map(menu => (
                    <Link className={`menu-item ${location.pathname === menu.link ? 'active' : ''}`} to={menu.link} onClick={() => smallScreenMatch && props.onToggle(false)}>
                        <span >{menu.label}</span>
                    </Link>
                ))
            }
        </Drawer>
    )
}
export default SideMenu;