import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import Divider from "@mui/material/Divider";

const GrowYourBusiness = () => {
    return (
        <Box position="relative">
            <Typography variant="h4" className="material-title">How can we help you grow?</Typography>
            <Box className="material-card">
                <Typography variant="h6" className="sub-title-box" marginBottom={1}>Tell us about your business.</Typography>
                
                <Typography variant="body2">
                    What do you right now to make it better, stronger and more profitable?
                </Typography>
                <Typography variant="body2">
                    <strong>
                        Get a free Business Evaluation today. This invitation is open to everyone. We can
                        help you improve your plan through Networking, Online Advertising, Internet Marketing, Free and Low Cost
                        Web Development, Announcements, tons of PR, and much more.
                        But we won't be able to assist you until we "meet" you. The Business Evaluation is free. There is
                        absolutely no "hard sell" or "obligation". Information...is FREE with us!
                    </strong>
                </Typography>
                <Typography variant="body2">
                    <List>
                        <ListItemText primary="Our Philosophy" secondary={`The \"Partner-to-Grow\" Business Networking Mission Statement.`} />
                        <ListItemText primary="Businesses to Watch" secondary={`Local Businesses, Non-Profits, Events, and SC Business Owners on the move!`} />
                        <ListItemText primary="SC Business Blog" secondary={`Comment on the Minority Builders Business Blog - A Journey of Many Happy Returns.`} />
                        <ListItemText primary="Business Hints and Tips, local Employment Opportunities, and Montizations." />
                    </List>
                </Typography>
                <Typography variant="h6" className="sub-title-box" marginBottom={1}>Business Owners today are at a disadvantage if they do not keep up with the changing
                    times and technology, and keep up with the competition. Minority Businesses even more so.</Typography>
                
                <Typography variant="body2">
                    <strong>Small Businesses often fail to keep up with progress because : </strong>
                </Typography>
                <List>
                    <ListItem>They don't know how and do not know where to get the skills needed</ListItem>
                    <ListItem>They don't know about services and financial opportunities that can be had</ListItem>
                    <ListItem>They are scared to ask for help, thinking they cannot afford it anyway</ListItem>
                    <ListItem>They think there is no one in the business who can manage technical progression</ListItem>
                </List>
                <Typography variant="body2" marginBottom={1}>We are here to help. We will tell you about services and financial opportunities that are
                    there for you right now to take advantage of. Do not be afraid. Partnering to Grow is all about building
                    relationships of Trust. We will teach you and show you how you can manage you Minority Business
                    efficiently and effectively so you, too, can grow and change with the times and get the progressive edge
                    on your competition.</Typography>
                <Typography variant="body2" marginBottom={1}><strong>Minority Builders Association is here to make sure you have all the tools you need to succeed.
                </strong>
                </Typography>
            </Box>
        </Box>
    );
}

export default GrowYourBusiness;