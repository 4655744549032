import { Box, List, ListItem, Typography } from "@mui/material";

const WhyGBA = () => {
    return (
        <Box position="relative">
            <Typography variant="h4" className="material-title">Why Join Credai Gandhinagar</Typography>
            <Box className="material-card">

                <Typography variant="h6" className="title-box">There are many reasons to join a Builders' Association. Here are some of them:</Typography>
                <List sx={{ listStyleType: 'disc', pl: 4 }}>
                    <ListItem>Networking with other businesses</ListItem>
                    <ListItem>Information and training</ListItem>
                    <ListItem>Promote yourself as a professional</ListItem>
                    <ListItem>Influence the future of your industry</ListItem>
                    <ListItem>Build your business skills and knowledge</ListItem>
                    <ListItem>Connect with customers</ListItem>
                    <ListItem>Access to promotional materials</ListItem>
                </List>
                <Typography variant="h6" className="sub-title-box">Builder</Typography>
                <Box paddingTop={1}>
                    <Typography variant="body2">As a builder, you know how tough it is to make a living thesedays. Not only do you have the usual
                        business challenges of managing workers and suppliers attracting buyers and keeping the books
                        straight, but you have to keep up with the latest codes and regulations from central, state and
                        local authorities ou've got to be on the job site every day, not sitting in the office researching
                        the government's latest rules. How do you stay up-to-date, grow your business and still have time
                        for your family</Typography>
                    <Typography variant="body2">A big part of the answer is to join the Credai Gandhinagar (Gandhinagar Builders Association-GBA).
                        Our association has an organization in place to keep you informed on the latest regulations and
                        trends in the housing & commercial industry, give you ideas on how to expand your business and make
                        your voice heard in GUDA & Government of Gujarat.</Typography>
                </Box>
                <Typography variant="h6" marginTop={1} className="sub-title-box">Invaluable Networking Opportunities</Typography>
                <Box paddingTop={1}>
                    <Typography variant="body2">What better way to expand your business circle than by getting involved in a trade organization
                        dedicated to the building industry? As an alliance of over 125 members of Credai Gandhinagar
                        (Gandhinagar Builders Association-GBA) and 600-plus members in GIHED, we provide hundreds of ways
                        for you to meet and build relationships with fellow professionals, customers, and suppliers.
                        Membership meetings, Outings, Holiday night and seminars are networking opportunities available to
                        our members.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default WhyGBA;