
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { Projects } from '../Assets/Constants';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid/Grid';
import Box from '@mui/material/Box';

const ProjectDetails = () => {

    const [filter, setFilter] = useState("");
    return (
        <Box padding="10px">
            <br />
            <TextField
                label="Search for Types | Companies..."
                fullWidth
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
            />
            <div id="no_match" className="center"></div>
            <Box padding="10px 0">
                <Grid display={"flex"} flexDirection={"row"} justifyContent={"center"} flexWrap={"wrap"} gap={2}>
                    {
                        Projects.filter(project => filter ? (project.type.toLowerCase().indexOf(filter.toLowerCase()) >= 0 || project.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0) : true).map(project => (
                            <Card sx={{ width: 345 }}>
                                <div className="card-image-container">
                                    <CardMedia
                                        className="card-image"
                                        component="img"
                                        height="160"
                                        image={project.companyLogo}
                                        alt=""
                                    />
                                    <div className="overlay">
                                        <div className="text">{project.name}</div>
                                    </div>
                                </div>
                                <Typography variant="subtitle1" textAlign={"center"} className="title-box">{project.name}</Typography>
                                <CardContent>
                                    <Typography variant="button">Type</Typography>
                                    <Typography variant="body2">{project.type}</Typography>
                                    <Typography variant="button">Address</Typography>
                                    <Typography variant="body2">{project.address}</Typography>
                                    <Typography variant="button">Contact</Typography>
                                    <Typography variant="body2">{project.contactNo}</Typography>
                                </CardContent>
                            </Card>
                        ))
                    }
                </Grid>
            </Box>
        </Box>
    );
}

export default ProjectDetails;