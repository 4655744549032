import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

interface ImageDialogType {
    width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    open: boolean;
    image: any;
    handleClose: () => void;
}

const ImageDialog = (props: ImageDialogType) => {
    return (
        <Dialog
        sx={{zIndex: '99999'}}
            maxWidth={props.width || 'lg'}
            open={props.open}
            onClose={props.handleClose}
        >
            <DialogContent>
                <img src={props.image} alt="" />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ImageDialog;