import { Box, Typography } from "@mui/material";

const PresidentMessage = () => {
    return (
        <Box position="relative">
            <Typography variant="h4" className="material-title">President's Message</Typography>
            <div className="material-card">
                <Typography variant="body2" marginBottom={2}>
                    <em>It is my proud privilege to put a message from my desk for the existing member of Credai
                        Gandhinagar &
                        aspiring ones.</em>
                </Typography>
                
                <Typography variant="body2" marginBottom={2}>
                    <em>
                        We should be proud of our profession which is very noble one in the way it serves the basic need of a
                        individual a “SHELTER” for stay & accommodation business & flourish.
                    </em>
                </Typography>
                
                <Typography variant="body2" marginBottom={2}>
                    <em>Association apart from collective interaction & approach for the business prospects &
                        general interest of the profession, is a strength of unity-and ethical approach to profession &
                        interacting with regulatory authorities for broad & visionary approach of smooth & speedy development of
                        housing industry.</em>
                </Typography>
                
                <Typography variant="body2" marginBottom={2}>
                    <em>Thank you for visiting the Credai Gandhinagar (Gandhinagar Builders Association-GBA)'s
                        interactive website. Whether you are one of the approximately XYZ members of our association or an
                        interested consumer in our industry, we trust you will find the information and communication
                        informative and beneficial.</em>
                </Typography>
                
                <Typography variant="body2" marginBottom={2}>
                    <em>The Credai Gandhinagar (Gandhinagar Builders Association-GBA) is one of our State’s
                        most established and respected associations representing single and multi family builders, renovators,
                        land developers, trades, suppliers and general professionals bound together to provide the region with
                        housing and communities that represent innovation and integrity.
                    </em>
                </Typography>
                
                <Typography variant="body2" marginBottom={2}>
                    <em>The Credai Gandhinagar (Gandhinagar Builders Association-GBA) values the opportunity
                        and community in which they serve your interest in our associationson and its membership is very much
                        appreciated. Please ensure to contact the credaigandhinagar@gmail.com, should you have any further
                        inquires or questions that we may help you with.</em>
                </Typography>
                
                
                <Typography variant="body2" marginBottom={2}><em>Sincerely,</em></Typography>
                <Typography variant="body2" marginBottom={2}><strong><i>President - Credai Gandhinagar (Gandhinagar Builders Association-GBA)</i></strong></Typography>
            </div>
        </Box>
    );
}

export default PresidentMessage;