import { Box, Grid, Typography, Button, List, ListItem } from "@mui/material";
import { Link } from 'react-router-dom';
import Logo from '../Assets/Images/credai-logo.png';
import { Carousel } from 'react-responsive-carousel';
import HandshakeIcon from '@mui/icons-material/Handshake';

import Image9 from '../Assets/Images/image-9.jpg'
import Image10 from '../Assets/Images/image-10.jpg'
import Image11 from '../Assets/Images/image-11.jpg'
import Image12 from '../Assets/Images/image-12.jpg'
import Image13 from '../Assets/Images/image-13.jpg'

const Home = () => {
    return (
        <Box>
            <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
                <div>
                    <img src={Image9} style={{ width: "100%" }} />
                </div>
                <div>
                    <img src={Image10} style={{ width: "100%" }} />
                </div>
                <div>
                    <img src={Image11} style={{ width: "100%" }} />
                </div>
                <div>
                    <img src={Image12} style={{ width: "100%" }} />
                </div>
                <div>
                    <img src={Image13} style={{ width: "100%" }} />
                </div>
            </Carousel>
            <Box padding="15px">
                <Grid container flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} gap={1}>
                    <Grid item xs={8}>
                        <Typography variant="h6" className="sub-title-box">
                            Welcome to Credai Gandhinagar (Gandhinagar Builders Association-GBA)
                        </Typography>
                    </Grid>
                    <Grid item container xs={3} justifyContent={"flex-end"}>
                        <Link to={"/Files/JoiningForm.pdf"} target="_blank" download={true}>
                            <Button variant="contained" startIcon={<HandshakeIcon />}>Join Us</Button>
                        </Link>
                    </Grid>
                </Grid>
                <Grid container flexDirection="row" >
                    <Grid item alignSelf={"center"} justifyItems={"center"}>
                        <img width="150px" alt="Image" src={Logo} />
                    </Grid>
                    <Grid item xs={10}>
                        <List>
                            <ListItem>
                                <Typography variant="body2">
                                    The Credai Gandhinagar (Gandhinagar Builders Association-GBA) is a
                                    trade association for the
                                    residential & commercial construction and development industry.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant="body2">
                                    The Association provides a forum through which these businesses can work together to remain
                                    informed regarding new and proposed policies advanced by the local, state and federal governments which
                                    affect the housing industry.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant="body2">
                                    The Association acts as a source of timely and reliable information concerning the state of the
                                    local building industry and works to eliminate overly restrictive and costly building laws and
                                    regulations that drive up the cost of housing.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant="body2">
                                    In year 1992 different builders-individual & organizations independently working in Gandhinagar
                                    have come on common platform for uniform & proper approach to various problems pertaining constructions
                                    laws in force by the state government-thus Credai Gandhinagar came in existence.
                                </Typography>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
                <List>
                    <ListItem>
                        <Typography variant="body2">
                            The Credai Gandhinagar (Gandhinagar Builders Association-GBA) is part
                            of organizations who share a
                            common mission: to serve the housing industry and provide expanding opportunities for all consumers
                            to have safe, decent and affordable housing. Individual members join local associations, which in
                            turn are affiliated with the Credai Gandhinagar (Gandhinagar Builders Association-GBA).
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body2">
                            This structure enables the association to address regulatory issues and insure representation
                            of the Construction industry at all levels of government. Portions of each member's local
                            association dues go to support Credai Gandhinagar. The role of the Credai Gandhinagar is to support
                            the local associations
                            throughout the state of Gujarat, is also attached to the GIHED and CREDAI GUJRAT (FREDAG) Credai
                            Gandhinagar also
                            recognizes the service of outstanding association members with the Hall of Fame and Builder,
                            Associate & Executive Officer of the Year programs.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body2">
                            Your continued support of your local home builders association is critical to the continued
                            growth of the housing industry and your business, ensuring the concerns of builders are heard in the
                            State House and on Gujarat. Together we can make a difference!
                        </Typography>
                    </ListItem>
                </List>

                <Box>
                    <Typography variant="h6" className="title-box" marginBottom={1}>Mission & Vision</Typography>
                    <Typography variant="body2">
                        Our vision for the Credai Gandhinagar is a pro-active, dynamic and unified association which foresees a
                        business environment
                        where new home builder, renovator, developer and non-builder members have earned the respect and trust of
                        consumers and government policy-makers.
                    </Typography>

                    <Typography variant="h6" className="sub-title-box" marginY={1}>Our Motto</Typography>
                    <List>
                        <ListItem><Typography variant="body2">Business with ethics.</Typography></ListItem>
                        <ListItem><Typography variant="body2">Unity amongst the builders</Typography></ListItem>
                        <ListItem><Typography variant="body2">Timely & quality construction to the client</Typography></ListItem>
                        <ListItem><Typography variant="body2">We follow code of conduct</Typography></ListItem>
                    </List>
                </Box>

                <Box>
                    <Typography variant="h6" className="sub-title-box" marginY={1}>Our Activities</Typography>
                    <Typography variant="body2">
                        Our Association activities are driven by our membership; directed to the activities, issues and needs of our members.
                        Some of our past and planned activities include:
                    </Typography>
                    <List >
                        <ListItem><Typography variant="body2">Regular monthly meetings with an informational speaker each evening.</Typography></ListItem>
                        <ListItem><Typography variant="body2">One or two Professional Development Days, consisting of day-long information</Typography></ListItem>
                        <ListItem><Typography variant="body2">Builder-only events to discuss builder-related issues</Typography></ListItem>
                        <ListItem><Typography variant="body2">Regular "Technical evenings" on various topics related to Construction.</Typography></ListItem>
                        <ListItem><Typography variant="body2">Technical visits to places of importance.</Typography></ListItem>
                        <ListItem><Typography variant="body2">Regular Seminars and Workshops.</Typography></ListItem>
                        <ListItem><Typography variant="body2">Member-to-member trade show</Typography></ListItem>
                        <ListItem><Typography variant="body2">Member Social Activities</Typography></ListItem>
                        <ListItem><Typography variant="body2">Annual out-station tour.</Typography></ListItem>
                        <ListItem><Typography variant="body2">Regular family get-togethers</Typography></ListItem>
                        <ListItem><Typography variant="body2">Excellent Fellowship Amity and Solidarity among our members</Typography></ListItem>
                        <ListItem><Typography variant="body2">Regular information updates through email newsletters and "members only" website access.</Typography></ListItem>
                        <ListItem><Typography variant="body2">...and many more.</Typography></ListItem>
                    </List>
                </Box>
            </Box>
        </Box>
    );
}

export default Home;