import React, { useEffect, useState } from 'react';
import { HeaderMenuList } from '../Assets/Constants';
import { Link, useLocation } from 'react-router-dom';

import { styled, Theme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CSSObject } from '@emotion/react';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import HandshakeIcon from '@mui/icons-material/Handshake';
import TryCityFestImg from './../Assets/Images/PropertyFest/ic-tricity-fest.png';

interface PropTypes {
    drawerOpen: boolean;
    onToggle: (matches: boolean) => void
}

const Header = (props: PropTypes) => {

    const location = useLocation();
    const smallScreenMatch = useMediaQuery('(max-width: 750px)');
    const [open, setOpen] = useState(true);

    useEffect(() => {
        setOpen(!smallScreenMatch);
        props.onToggle(!smallScreenMatch);
    }, [smallScreenMatch])

    useEffect(() => {
        setOpen(props.drawerOpen);
    }, [props.drawerOpen])

    const handleDrawerToggle = () => {
        setOpen(!open);
        props.onToggle(!open);
    };

    return <div className="menu-wrapper">
        <div className='menus'>
            <MenuIcon className="btn-sideMenu" onClick={handleDrawerToggle} />
            {smallScreenMatch && <h2 className='header-title'> Credai Gandhinagar </h2>}
            {
                !smallScreenMatch && HeaderMenuList.map(menu => (
                    <Link className={`menu-item ${location.pathname === menu.link ? 'active' : ''}`} to={menu.link}>
                        <span>{menu.label}</span>
                    </Link>
                ))
            }
        </div>
        <Link to="/Files/JoiningForm.pdf" target="_blank" download={true}>
            <Button variant="outlined" startIcon={<HandshakeIcon />}>Join Us</Button>
        </Link>
        {/* <Link to="/">
            <Button variant="outlined" startIcon={<img  src={TryCityFestImg} alt="" height={"25px"} />}>Property Fest 2023</Button>
        </Link> */}
    </div>
}
export default Header;