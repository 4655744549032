import { Box, Typography } from "@mui/material";

const MemberBenefit = () => {
    return (
        <Box position="relative">
            <Typography variant="h4" className="material-title">Member Benefit</Typography>
            <Box className="material-card">
                <Typography variant="h6" className="title-box">What Credai Gandhinagar Can Do For Builders and Associates of the Building Industry...
                </Typography>
                
                <Typography variant="subtitle1" marginY={1} className="sub-title-box">Government & Regulatory Affairs</Typography>
                <Typography variant="body2">The most important benefit of membership is our involvement in the government and
                    regulatory arenas. By representing you at the local, state and national levels, the association keeps
                    you in business! Members are continually kept up to date regarding industry impacting laws and
                    regulations.</Typography>
                

                <Typography variant="subtitle1" marginY={1} className="sub-title-box">Networking Opportunities</Typography>
                <Typography variant="body2">
                    What better way to expand your business circle than by getting involved in a trade organization
                    dedicated to the building industry? Membership meetings, outings and seminars are just a few of the
                    networking opportunities available to members
                </Typography>
                

                <Typography variant="subtitle1" marginY={1} className="sub-title-box">3 in 1: A Package Deal</Typography>
                <Typography variant="body2">By joining your local home builders association, you are also joining the state and
                    national associations like GIHED, CREDAI Gujarat (FREDAG) and also CREDAI INDIA. You will receive a wide
                    range of services and benefits from each one. Staff members at each level in our three-tiered federation
                    are here for our members and ready to assist you and your business.</Typography>
                

                <Typography variant="subtitle1" marginY={1} className="sub-title-box">Advertising</Typography>
                <Typography variant="body2">Advertising opportunities are available through local, state and national newsletters
                    and magazines, all of which are distributed to the entire association membership.</Typography>
                

                <Typography variant="subtitle1" marginY={1} className="sub-title-box">Marketing Opportunities / Sponsorships</Typography>
                <Typography variant="body2">Want to increase your business's visibility and credibility? Why not sponsor an
                    association meeting or event? You'll have a captive audience at your fingertips and a great chance to
                    promote your latest idea or product- all while supporting the association.</Typography>
                

                <Typography variant="subtitle1" marginY={1} className="sub-title-box">Strength in Numbers</Typography>
                <Typography variant="body2">Standing alone, you are one voice, but united with others in the association, many
                    great things can be accomplished for the good of the building industry and the community in which we
                    live. The association is also part of several coalitions that were created to not only help shape the
                    future of our industry, but to ensure that you will have one.</Typography>
                

                <Typography variant="subtitle1" marginY={1} className="sub-title-box">Professional Image</Typography>
                <Typography variant="body2">The image you portray to the buying public can make or break your business. By joining
                    the home builders association, you are showing the public that you care about your industry, your peers,
                    your customers and the community. Membership in the association will set you apart from others and
                    distinguish you as truly committed to your business and the housing industry.</Typography>
            </Box>
        </Box>
    );
}

export default MemberBenefit;