import { Box, Card, Grid, ListItemText, Typography } from "@mui/material";

const ContactUs = () => {
    return (
        <Box position="relative">
            <Typography variant="h4" className="material-title material-blue">Contact Us</Typography>
            <Card className="material-card">
                <Typography variant="h6" className="title-box"> Credai Gandhinagar (Gandhinagar Builders Association-GBA)</Typography>
                <Typography variant="subtitle1" className="sub-title-box" marginY={1}>Office Address :</Typography>
                <Typography variant="body1">407, Shreerang Heights, Opp. Bhajipura Patiya Gandhinagar - Koba Hoghway,
                    Kudasan, Gandhinagar - 382421 Gujarat, (INDIA)</Typography>
                <Typography variant="subtitle1" className="sub-title-box" marginY={1}>Contact Details :</Typography>
                <Grid display={"flex"} flexDirection={"row"} gap={2} flexWrap={'wrap'}>
                    <ListItemText primary="Mr. Kiran D. Patel (President)" secondary="+91 97255 02416" />
                    <ListItemText primary="Mr. S.K. Patel (Hon. Secretary)" secondary="+91 99740 79650" />
                    <ListItemText primary="Email" secondary="credaigandhinagar@gmail.com" />
                    <ListItemText primary="Website" secondary="www.credaigandhinagar.com" />
                </Grid>
            </Card>
        </Box >
    );
}

export default ContactUs;