import { Box, List, ListItem, Typography } from "@mui/material";

const WeCommitted = () => {
    return (
        <Box position="relative">
            <Typography variant="h4" className="material-title">We are Committed</Typography>
            <Box className="material-card">
                <Typography variant="subtitle1" className="title-box">The Credai Gandhinagar (Gandhinagar Builders Association-GBA), is dedicated to
                    serving the entire building and housing industry. To improve the business climate for its members,
                    Credai Gandhinagar
                    encourages and promotes: </Typography>
                <List>
                    <ListItem>The growth, strength and image of the building and housing industry by providing governmental
                        advocacy, networking and social opportunities, education and information, and positive public
                        relations.</ListItem>
                    <ListItem>The goodwill, confidence and support of its members, the general public and governmental agencies at
                        all levels; the highest professional standards in the building and housing industry.</ListItem>
                    <ListItem>Member involvement in the communities in which they live and do business.</ListItem>
                    <ListItem>The proactive development of laws, regulations, and standards that affect the building and housing
                        industry.</ListItem>
                    <ListItem>The production, preservation and management of a variety of quality housing and commercial buildings
                        to meet the various needs of our communities.</ListItem>
                </List>
                <Typography variant="subtitle2">
                    <strong>
                    The Credai Gandhinagar strives to achieve its mission through the dedicated and
                    hardworking cadre of
                    volunteer members and staff who believe in what the Association represents.
                    </strong>
                </Typography>
            </Box>
        </Box>
    );
}

export default WeCommitted;